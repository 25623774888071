import { Children, type FunctionComponent, type PropsWithChildren, type ReactElement, useCallback, useEffect, useState } from 'react';
import { windowIsSmall } from '../../../helpers/breakpoints/breakpoint.helpers';
import { generateDataSelector } from '../../../helpers/general-helper/general-helper';
import { handleKeys } from '../../../helpers/keyboard/keyboard.helper';
import { useNotSmallBreakpointListener } from '../../../hooks/breakpoints/breakpoint-listeners.hook';
import { ChevronDownIcon, ChevronUpIcon } from '../../svg/icons.component';
import { activeTabOffset } from './responsive-tabs.css';

const Indicator: FunctionComponent<{ isExpanded: boolean }> = ({ isExpanded }) => {
	return (
		<div data-testid="indicator" className="dn-ns f-inherit lh-solid ml3 flex items-center" data-automation="expand">
			{isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
		</div>
	);
};

export type ResponsiveTabProps = {
	name: string;
	onClick?: Function;
	isActive?: boolean;
	isExpanded?: boolean;
	startOpen?: boolean;
	tabClasses?: string;
	activeTabClasses?: string;
	inactiveTabClasses?: string;
	analyticsHook?: string;
	tabContentClasses?: string;
};

export const ResponsiveTab: FunctionComponent<PropsWithChildren<ResponsiveTabProps>> = ({
	name,
	children,
	onClick,
	isActive = false,
	isExpanded = false,
	tabClasses = '',
	activeTabClasses = '',
	inactiveTabClasses = '',
	analyticsHook,
	tabContentClasses = ''
}) => {
	// Tab panels start closed on mobile. Avoid layout shift by setting display none for mobile size until client side mount.
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		setLoading(false);
	}, []);
	const panelLoadingClass = loading ? 'dn db-ns' : '';

	const handleUserClick = () => onClick?.(name);
	const activeTabClassesWithOffset = `${activeTabClasses} ${activeTabOffset}`.trim();
	const className = `pointer ${tabClasses} ${isActive ? activeTabClassesWithOffset : inactiveTabClasses}`.trim();

	return (
		<>
			<div
				role="tab"
				className={className}
				aria-label={`${name} tab`}
				onClick={handleUserClick}
				onKeyDown={handleKeys(['Enter', ' '], handleUserClick)}
				data-analytics={generateDataSelector('tab', analyticsHook)}
				tabIndex={0}>
				<div className={`w-100 w-auto-ns flex title justify-between ${tabContentClasses}`}>
					<span>{name}</span>
					<Indicator isExpanded={isExpanded && !loading} />
				</div>
			</div>
			<div role="tabpanel" className={`w-100 order-last-ns overflow-y-auto-ns ${panelLoadingClass}`}>
				{isExpanded && children}
			</div>
		</>
	);
};

export type ResponsiveTabGroupChild = React.ReactElement<ResponsiveTabProps>;

export type ResponsiveTabGroupProps = {
	tabGroupClasses?: string;
	tabListClasses?: string;
	startingTabName: string;
	testId?: string;
	children: ResponsiveTabGroupChild | ResponsiveTabGroupChild[];
};

export const ResponsiveTabGroup: FunctionComponent<ResponsiveTabGroupProps> = ({
	tabGroupClasses,
	children,
	startingTabName,
	tabListClasses,
	testId
}) => {
	const [activeTab, setActiveTab] = useState(startingTabName || '');
	const [expandedTabs, setExpandedTabs] = useState(startingTabName ? [startingTabName] : []);

	// Mobile tabs start closed. Coordinates with initial styling in ResponsiveTab to avoid layout shift.
	useEffect(() => {
		if (windowIsSmall()) {
			setExpandedTabs([]);
		}
	}, []);

	// Listen for window size change to close all but the active tab.
	const notSmallCallback = useCallback(() => setExpandedTabs(activeTab ? [activeTab] : []), [activeTab]);
	useNotSmallBreakpointListener(notSmallCallback);

	const handleTabClicked = (tabName: string) => {
		if (windowIsSmall()) {
			// Toggle the named tab tab open/shut, leaving the rest as-is. Set it as active if opening.
			if (expandedTabs.includes(tabName)) {
				setExpandedTabs((expandedTabsState) => expandedTabsState.filter((name) => name !== tabName));
			} else {
				setActiveTab(tabName);
				setExpandedTabs((expandedTabsState) => [...expandedTabsState, tabName]);
			}
		} else {
			// Only one tab open at a time.
			setActiveTab(tabName);
			setExpandedTabs([tabName]);
		}
	};

	const Tabs = Children.map(children, (child: ReactElement, tabIndex) => {
		// Child can be falsy when tab conditionally rendered
		if (!child) {
			return null;
		}

		const tabName = child.props.name;
		return (
			<ResponsiveTab
				{...child.props}
				key={`${tabName}-${tabIndex}`}
				onClick={handleTabClicked}
				isActive={tabName === activeTab}
				isExpanded={expandedTabs.includes(tabName)}>
				{child.props.children}
			</ResponsiveTab>
		);
	});

	return (
		<section className={tabGroupClasses} data-testid={testId}>
			<div className={`flex flex-wrap flex-column flex-row-ns ${tabListClasses || 'bb-ns b--theme-grey-light'}`}>{Tabs}</div>
		</section>
	);
};
