import { type CSSProperties, type FunctionComponent } from 'react';
import { type ImageFieldsFragment } from '../../../../__generated__/graphql-client-types';
import { MAX_PAGE_WIDTH } from '../../../../constants/breakpoints';
import { type ContentComponent, type ListContent, type SaleSection, type TextContent } from '../../../../types/construct.types';
import { DelimitedString } from '../../../../utils/string/general.utils';
import { CloudinaryImage } from '../../../images/cloudinary-image/cloudinary-image.component';
import { MaybeBackgroundImage } from '../../../images/maybe-background-image/maybe-background-image.component';
import { type ContentComponentHelper } from '../../render-helper/render.helper';
import { addContentHandler } from '../add-content-handler';
import { banner, overlayIcon } from './sale-scetion-handler.css';

export const SaleSectionHandler: ContentComponent<SaleSection> = ({
	content: {
		fields: {
			heading,
			media_image: { image },
			related_categories,
			doorbuster_products,
			doorbusters_heading,
			related_articles_heading,
			related_articles,
			media_image_overlay: { image: overlayImage }
		}
	},
	renderer
}) => (
	<>
		{/* Section Heading */}
		<SaleSectionHeading backgroundImage={image ?? undefined} heading={heading} overlayImage={overlayImage || undefined} />

		{/* Related Categories */}
		<ListContentSection list={related_categories} renderer={renderer} />

		{/* Door Busters */}
		<ListContentSection heading={doorbusters_heading} list={doorbuster_products} renderer={renderer} />

		{/* Related Articles */}
		<ListContentSection heading={related_articles_heading} list={related_articles} renderer={renderer} />
	</>
);

type SaleSectionHeadingProps = {
	backgroundImage?: ImageFieldsFragment;
	heading: TextContent;
	overlayImage?: ImageFieldsFragment;
};
const SaleSectionHeading: FunctionComponent<SaleSectionHeadingProps> = ({ backgroundImage, heading, overlayImage }) => {
	const headingClasses = new DelimitedString('tc tl-ns absolute bottom-0 ma0 pa2 w-100', ' ');
	const wrapperStyle: CSSProperties = {};

	if (backgroundImage?.id) {
		headingClasses.push('theme-white');
		wrapperStyle.background = 'linear-gradient(0deg,rgba(0,0,0,.85) 0,hsla(0,0%,9%,.5) 45%,transparent)';
	}
	return (
		<section className="w-100 mt3 pb2 ph2-ns">
			<MaybeBackgroundImage
				publicID={backgroundImage?.id}
				options={{ width: MAX_PAGE_WIDTH, height: 200, type: backgroundImage?.imageType, crop: 'fill' }}
				className={`aspect-ratio ${banner}`}>
				<div className="h-100" style={wrapperStyle}>
					<h2 className={headingClasses.toString()}>{heading}</h2>
				</div>
				{overlayImage?.id && (
					<div className={`absolute ${overlayIcon} center bottom-0  bottom--1-ns`}>
						{overlayImage && (
							<CloudinaryImage
								publicID={overlayImage.id}
								description={overlayImage.description}
								options={{ height: 150, width: 150 }}
							/>
						)}
					</div>
				)}
			</MaybeBackgroundImage>
		</section>
	);
};
type ListContentSectionProps = {
	heading?: string;
	list: ListContent;
	renderer: ContentComponentHelper<SaleSection>;
};
const ListContentSection: FunctionComponent<ListContentSectionProps> = ({ heading, list, renderer }) => {
	return list.items.length ? (
		<section className="w-100">
			{heading && <h3>{heading}</h3>}
			{renderer.render(list, undefined, { ListContent: { totalItemCount: list.items.length } })}
		</section>
	) : null;
};

addContentHandler('deals-template', 'ObjectContent', 'sale-section@2', SaleSectionHandler);
