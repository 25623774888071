import { type FunctionComponent, type ReactElement, type ReactNode, type MouseEventHandler, useReducer } from 'react';
import { INITIAL_SIDEBAR_STATE, sidebarReducer, type SidebarStatus } from '../../reducers/responsive-sidebar/responsive-sidebar.reducer';
import { ClickableElement, StyledButton, TextButton } from '../buttons';
import { Overlay } from '../common-components/overlay/overlay.component';
import { CloseIcon } from '../svg/icons.component';

// #region Sidebar Trigger
export type SidebarTriggerProps = {
	trigger: string | ReactElement;
	summary?: string;
	direction: SidebarDirection;
	onClick: MouseEventHandler;
};

const SidebarTrigger: FunctionComponent<SidebarTriggerProps> = ({ trigger, direction, onClick, summary }) => {
	const triggerOrrientation = direction === 'left' ? 'justify-start' : 'justify-end';
	const ariaLabel = typeof trigger === 'string' ? trigger : 'open';
	const buttonProps = { onClick, ariaLabel };

	return (
		<div className="dn-l dn-p">
			<div className={`flex ${triggerOrrientation} ${summary ? 'justify-between' : ''} items-center ph2 pt2`}>
				{summary ? <span>{summary}</span> : null}
				{typeof trigger === 'string' ? (
					<StyledButton buttonStyle="SECONDARY" {...buttonProps}>
						{trigger}
					</StyledButton>
				) : (
					<ClickableElement {...buttonProps}>{trigger}</ClickableElement>
				)}
			</div>
		</div>
	);
};
// #endregion

type SidebarDirection = 'left' | 'right';

/**
 * helper to generate the proper display and animation classes
 */
function generateSidebarClasses(direction: SidebarDirection, status: SidebarStatus): string {
	/* basic display classes */
	const classes: string[] = ['w-75', 'w-25-l', 'h-100', 'db-l'];
	/* animation related classes */
	switch (status) {
		case 'opening':
			classes.push(direction === 'left' ? 'slideInLeft' : 'slideInRight');
			break;
		case 'closing':
			classes.push(direction === 'left' ? 'slideOutLeft' : 'slideOutRight');
			break;
		case 'closed':
			classes.push('dn');
			break;
		default:
			break;
	}
	if (status !== 'closed') {
		classes.push('fixed');
		classes.push('top-0');
		classes.push(`${direction}-0`);
		classes.push('shadow-1');
		classes.push('bg-theme-white');
		/* needed to be above the overlay */
		classes.push('z-4');
	}
	/* build the class string */
	return classes.join(' ');
}
type ChildrenTheme = 'theme-grey-lighter' | 'theme-white';

function generateChildrenClasses(theme?: ChildrenTheme) {
	const classes = ['w-100'];
	if (theme) {
		classes.push(`bg-${theme}`);
	}
	return classes.join(' ');
}

// #region Responsive Sidebar
export type ResponsiveSidebarProps = {
	trigger: string | ReactElement;
	summary?: string;
	children: ReactNode;
	content: ReactNode;
	direction?: SidebarDirection;
	childrenTheme?: ChildrenTheme;
};

/**
 * Used to responsively hide side bar content in a drawer, but to simply render the sidebar on large devices
 * Use cases:
 *   - side navigation
 *   - search facets
 *   - etc
 */
export const ResponsiveSidebar: FunctionComponent<ResponsiveSidebarProps> = ({
	content,
	trigger,
	direction = 'left',
	children,
	summary,
	childrenTheme
}) => {
	const [{ status }, dispatch] = useReducer(sidebarReducer, INITIAL_SIDEBAR_STATE);
	const closeButtonOrrientation = direction === 'left' ? 'justify-end' : 'justify-start';

	function onTriggerClick() {
		dispatch({ type: 'open' });
	}

	function onClickClose() {
		dispatch({ type: 'close' });
	}

	function onAnimationEnd() {
		dispatch({ type: 'animation-end' });
	}

	return (
		<div className="flex flex-column flex-row-l nl2 nr2 ma0-ns">
			{status === 'open' ? <Overlay style="light" onClick={onClickClose} /> : null}
			{/* Side Bar */}
			<div
				className={`${generateSidebarClasses(direction, status)} overflow-y-auto`}
				data-testid="sidebar"
				onAnimationEnd={onAnimationEnd}
				style={{ animationDuration: `0.25s`, animationFillMode: 'both' }}>
				{/* Close Button */}
				<div className={`flex dn-l ${closeButtonOrrientation}`}>
					<TextButton ariaLabel="close" onClick={onClickClose}>
						<CloseIcon className="pa3" />
					</TextButton>
				</div>
				{/* Sidebar Content */}
				<div className="dn-p">{content}</div>
			</div>
			{/* Main Content */}
			<div className={generateChildrenClasses(childrenTheme)}>
				<SidebarTrigger trigger={trigger} onClick={onTriggerClick} direction={direction} summary={summary} />
				{children}
			</div>
		</div>
	);
};
// #endregion
