import { useQuery } from '@apollo/client';
import { type FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { configureRoute } from '../../../utils/routes/configure-route';
import { type SupportPageDocumentsQuery, type SupportPageDocumentsQueryVariables } from '../../__generated__/graphql-client-types';
import { PageLoading } from '../../components/common-components/loading/loading.component';
import { PageContainer } from '../../components/common-components/page/page-container/page-container.component';
import { ContentComponentHelper } from '../../components/construct-components/render-helper/render.helper';
import { ContentContainer } from '../../components/content-container/content-container.component';
import { FourOFour } from '../../components/error-components/404/404.component';
import { ResponsiveSidebar } from '../../components/responsive-sidebar/responsive-sidebar.component';
import { MenuIcon } from '../../components/svg/icons.component';
import { getSeoMetaData } from '../../helpers/construct/page-content.helper';
import { useTrackPageView } from '../../hooks/analytics/analytics.hooks';
import { SUPPORT_PAGE_DOCUMENTS } from '../../queries/construct/construct.queries';
import { type SupportMenuContent, type SupportPageContent } from '../../types/construct.types';

export const SupportPage: FunctionComponent = () => {
	const { pageName } = useParams<{ pageName?: string }>();
	const route = `/support/${pageName}`;

	useTrackPageView({ pageName: `support:${pageName}` });

	const { data, loading } = useQuery<SupportPageDocumentsQuery, SupportPageDocumentsQueryVariables>(SUPPORT_PAGE_DOCUMENTS, {
		variables: { input: { route } }
	});

	if (loading) {
		return <PageLoading />;
	}

	if (!data?.pageDocument || !data.supportMenuDocument) {
		return <FourOFour pageName="support" />;
	}

	const pageContent = new ContentComponentHelper<SupportPageContent>(data?.pageDocument);
	const menuContent = new ContentComponentHelper<SupportMenuContent>(data?.supportMenuDocument);
	const { title, description, isIndexed } = getSeoMetaData(pageContent.content.fields.seo);

	return (
		<PageContainer canonicalURL={route} pageTitle={title} metaDescription={description} shouldBlockIndexing={!isIndexed}>
			<ResponsiveSidebar
				trigger={
					<div className="flex items-center f3 theme-primary">
						<MenuIcon className="f1" /> {menuContent.content.fields.heading}
					</div>
				}
				content={
					<ContentContainer contentId={menuContent.contentId ?? ''}>
						{menuContent.render(menuContent.content.fields.sections)}
					</ContentContainer>
				}>
				<ContentContainer contentId={pageContent.contentId}>
					<div className="ml3">
						{pageContent.content.fields.heading && <h1>{pageContent.content.fields.heading}</h1>}
						{pageContent.render(pageContent.content.fields.sections)}
					</div>
				</ContentContainer>
			</ResponsiveSidebar>
		</PageContainer>
	);
};

export const RoutedSupportPage = configureRoute({ path: '/support/:pageName', DANGEROUSLY_IS_LIVE: true }, SupportPage);
